import { useState, useRef } from "react";
import { Play, Pause, SkipForward, SkipBack } from "lucide-react";

interface Track {
  title: string;
  duration: string;
  url: string;
}

const MusicPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTrackIndex, setCurrentTrackIndex] = useState<number | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const tracks: Track[] = [
    { 
      title: "Autumn Leaves", 
      duration: "4:32",
      url: "/music/autumn-leaves.mp3" 
    },
    { 
      title: "Blue in Green", 
      duration: "5:37",
      url: "/music/blue-in-green.mp3" 
    },
    { 
      title: "Take Five", 
      duration: "5:24",
      url: "/music/take-five.mp3" 
    },
  ];

  const handlePlayPause = (index: number) => {
    if (currentTrackIndex === index) {
      // Toggle play/pause for current track
      if (isPlaying) {
        audioRef.current?.pause();
      } else {
        audioRef.current?.play();
      }
      setIsPlaying(!isPlaying);
    } else {
      // Start playing new track
      if (audioRef.current) {
        audioRef.current.src = tracks[index].url;
        audioRef.current.play()
          .catch(error => {
            console.error("Error playing audio:", error);
          });
      }
      setCurrentTrackIndex(index);
      setIsPlaying(true);
    }
  };

  const handlePrevious = () => {
    if (currentTrackIndex === null) return;
    const newIndex = (currentTrackIndex - 1 + tracks.length) % tracks.length;
    handlePlayPause(newIndex);
  };

  const handleNext = () => {
    if (currentTrackIndex === null) return;
    const newIndex = (currentTrackIndex + 1) % tracks.length;
    handlePlayPause(newIndex);
  };

  return (
    <section id="music" className="py-20 bg-jazz-dark text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold mb-12 text-center">Our Music</h2>
        
        <div className="max-w-3xl mx-auto bg-black/30 rounded-xl p-6">
          <audio ref={audioRef} onEnded={() => setIsPlaying(false)} />
          
          <div className="space-y-4">
            {tracks.map((track, index) => (
              <div
                key={index}
                className="flex items-center justify-between p-4 hover:bg-white/10 rounded-lg transition-colors cursor-pointer"
                onClick={() => handlePlayPause(index)}
              >
                <div className="flex items-center">
                  <button className="p-2 hover:text-jazz-gold transition-colors">
                    {isPlaying && currentTrackIndex === index ? (
                      <Pause className="h-6 w-6" />
                    ) : (
                      <Play className="h-6 w-6" />
                    )}
                  </button>
                  <span className="ml-4">{track.title}</span>
                </div>
                <span className="text-gray-400">{track.duration}</span>
              </div>
            ))}
          </div>
          
          <div className="mt-8 flex justify-center space-x-6">
            <button 
              className="p-2 hover:text-jazz-gold transition-colors"
              onClick={handlePrevious}
            >
              <SkipBack className="h-6 w-6" />
            </button>
            <button
              onClick={() => currentTrackIndex !== null && handlePlayPause(currentTrackIndex)}
              className="p-2 hover:text-jazz-gold transition-colors"
            >
              {isPlaying ? (
                <Pause className="h-8 w-8" />
              ) : (
                <Play className="h-8 w-8" />
              )}
            </button>
            <button 
              className="p-2 hover:text-jazz-gold transition-colors"
              onClick={handleNext}
            >
              <SkipForward className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MusicPlayer;