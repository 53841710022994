import Navigation from "@/components/Navigation";
import Hero from "@/components/Hero";
import About from "@/components/About";
import MusicPlayer from "@/components/MusicPlayer";
import Performances from "@/components/Performances";
import BookingForm from "@/components/BookingForm";
import Contact from "@/components/Contact";

const Index = () => {
  return (
    <div className="min-h-screen">
      <Navigation />
      <Hero />
      <About />
      <MusicPlayer />
      <Performances />
      <BookingForm />
      <Contact />
    </div>
  );
};

export default Index;