const About = () => {
  return (
    <article id="about" className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 items-center">
          <div className="relative">
            <img
              src="https://images.unsplash.com/photo-1516450360452-9312f5e86fc7?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              alt="SouthTownSoul performing live at a jazz venue"
              className="rounded-lg shadow-xl"
              width="800"
              height="600"
              loading="lazy"
            />
          </div>
          <div className="mt-10 lg:mt-0">
            <h2 className="text-3xl font-bold text-jazz-dark mb-4">
              About SouthTownSoul
            </h2>
            <p className="text-gray-600 mb-6">
              Based in the heart of Hannover, SouthTownSoul brings together the finest jazz musicians 
              in the region. Our unique blend of traditional jazz and contemporary soul has been 
              moving audiences since our formation.
            </p>
            <p className="text-gray-600 mb-6">
              From intimate jazz clubs to major festivals, we deliver unforgettable performances 
              that capture the essence of jazz while adding our own distinctive flair.
            </p>
            <div className="grid grid-cols-2 gap-4 text-center">
              <div className="p-4 bg-jazz-dark text-white rounded-lg">
                <p className="text-3xl font-bold text-jazz-gold">100+</p>
                <p className="text-sm">Performances</p>
              </div>
              <div className="p-4 bg-jazz-dark text-white rounded-lg">
                <p className="text-3xl font-bold text-jazz-gold">5+</p>
                <p className="text-sm">Years Together</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default About;