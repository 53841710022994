import { MapPin, Phone, Mail } from "lucide-react";

const Contact = () => {
  return (
    <section id="contact" className="py-20 bg-jazz-dark text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold mb-12 text-center">Contact Us</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="text-center">
            <div className="inline-block p-3 bg-jazz-gold rounded-full mb-4">
              <MapPin className="h-6 w-6 text-jazz-dark" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Location</h3>
            <p className="text-gray-300">Hannover, Germany</p>
          </div>
          
          <div className="text-center">
            <div className="inline-block p-3 bg-jazz-gold rounded-full mb-4">
              <Phone className="h-6 w-6 text-jazz-dark" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Phone</h3>
            <p className="text-gray-300">+49 123 456 789</p>
          </div>
          
          <div className="text-center">
            <div className="inline-block p-3 bg-jazz-gold rounded-full mb-4">
              <Mail className="h-6 w-6 text-jazz-dark" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Email</h3>
            <p className="text-gray-300">info@southtownsoul.com</p>
          </div>
        </div>

        <div className="mt-16 text-center">
          <p className="text-gray-300">
            Follow us on social media for the latest updates and behind-the-scenes content
          </p>
          <div className="mt-4 space-x-4">
            <a href="#" className="text-jazz-gold hover:text-white transition-colors">
              Facebook
            </a>
            <a href="#" className="text-jazz-gold hover:text-white transition-colors">
              Instagram
            </a>
            <a href="#" className="text-jazz-gold hover:text-white transition-colors">
              YouTube
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;