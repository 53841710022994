import { ArrowRight } from "lucide-react";

const Hero = () => {
  return (
    <main id="home" className="relative min-h-screen flex items-center justify-center bg-jazz-dark text-white">
      <div
        className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1511192336575-5a79af67a629?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80')] bg-cover bg-center"
        style={{ opacity: 0.3 }}
        role="img"
        aria-label="Jazz band performing on stage"
      />
      
      <div className="relative z-10 text-center px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl sm:text-6xl font-bold mb-4">
          <span className="text-jazz-gold">South</span>Town<span className="text-jazz-gold">Soul</span>
        </h1>
        <p className="text-xl sm:text-2xl mb-8 max-w-2xl mx-auto">
          Experience the soul of Hannover through the rhythm of jazz
        </p>
        <a
          href="#booking"
          className="inline-flex items-center px-6 py-3 border-2 border-jazz-gold text-jazz-gold hover:bg-jazz-gold hover:text-jazz-dark transition-colors duration-300 rounded-full text-lg font-semibold"
          aria-label="Book SouthTownSoul for your event"
        >
          Book Us Now
          <ArrowRight className="ml-2 h-5 w-5" aria-hidden="true" />
        </a>
      </div>
    </main>
  );
};

export default Hero;