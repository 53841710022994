import { Calendar } from "lucide-react";

const Performances = () => {
  const upcomingShows = [
    {
      date: "2025-05-14",
      venue: "Sommerfest TWINCORE ",
      city: "Hannover",
      time: "20:00",
    },
    {
      date: "2025-12-13",
      venue: "Weihnachtsfeier",
      city: "Hannover",
      time: "15:00",
    },
    {
      date: "2025-xx-xx",
      venue: "tbd",
      city: "Hannover",
      time: "tbd",
    },
  ];

  return (
    <section id="performances" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold mb-12 text-center text-jazz-dark">
          Upcoming Performances
        </h2>

        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {upcomingShows.map((show, index) => {
            const date = new Date(show.date);
            const formattedDate = date.toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            });

            return (
              <div
                key={index}
                className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow"
              >
                <div className="p-6">
                  <div className="flex items-center mb-4">
                    <Calendar className="h-5 w-5 text-jazz-gold" />
                    <span className="ml-2 font-semibold">{formattedDate}</span>
                  </div>
                  <h3 className="text-xl font-bold mb-2">{show.venue}</h3>
                  <p className="text-gray-600">{show.city}</p>
                  <p className="text-gray-600">Time: {show.time}</p>
                  <button className="mt-4 w-full px-4 py-2 bg-jazz-dark text-white rounded hover:bg-jazz-primary transition-colors">
                    Details
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Performances;